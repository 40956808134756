import React, { memo, useCallback, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';

import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { Box } from 'native-base';
import * as Yup from 'yup';

import { RegisterProps } from 'Components/register/CreateNewUser';
import DateInput from 'Components/utility/DateInput';
import DatePicker from 'Components/utility/DatePicker';
import Footer from 'Components/utility/Footer';
import Form from 'Components/utility/Form';
import ActionBarHeader from 'Components/utility/Header/ActionBarHeader';
import ContentHeader from 'Components/utility/Header/ContentHeader';
import { Input } from 'Components/utility/Input';
import Main from 'Components/utility/Main';
import ScrollContainer from 'Components/utility/ScrollContainer';
import { NavigationProps } from 'Components/wizard';

import { MINOR_FLOW, REGISTER_CLEAR } from 'Reducers/register/types';

import i18n from 'Utilities/i18n';
import log from 'Utilities/log';
import { store } from 'Utilities/store';

import { RootState } from 'src/reducers';

const mapStateToProps = ({ config: { adultAges }, env: { app, country } }: RootState) => {
  return {
    adultAges,
    app,
    country,
  };
};

const connector = connect(mapStateToProps);

type Props = ConnectedProps<typeof connector> & NavigationProps<Register> & RegisterProps;

const RegisterPersonalInfo: React.FC<Props> = ({
  next,
  back,
  validateNameLength,
  validateIsMinor,
  validateDOBAndDisplayError,
  handleClickSignIn,
  showMinorRestrictionMessage,
  values: currentValues,
  adultAges,
  app,
  country,
}) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.errors.required'
        )
      )
      .trim(),
    lastName: Yup.string()
      .required(
        i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.errors.required'
        )
      )
      .trim(),
    dateOfBirth: Yup.string().required(
      i18n.t<string>(
        'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.errors.required'
      )
    ),
  });

  const onSubmitForm = useCallback(
    async (values: Register) => {
      try {
        const isValid = validateNameLength(values.firstName, values.lastName);

        if (!isValid) {
          return;
        }

        const register: Register = {
          ...currentValues,
          ...values,
        };

        const isMinor = validateIsMinor(values.dateOfBirth, adultAges[country]);

        if (isMinor) {
          if (app === 'llu5') {
            showMinorRestrictionMessage(adultAges[country]);
            return;
          }

          store.dispatch({ type: MINOR_FLOW, isMinorFlow: true, register });
          return;
        }

        next(register);
      } catch (err) {
        log.error(err);
      }
    },
    [
      validateNameLength,
      currentValues,
      validateIsMinor,
      adultAges,
      country,
      next,
      app,
      showMinorRestrictionMessage,
    ]
  );

  const onClickDateInput = useCallback(() => {
    setIsDatePickerVisible(!isDatePickerVisible);
  }, [isDatePickerVisible]);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isValid,
    isSubmitting,
    errors,
    touched,
    values,
  } = useFormik({
    initialValues: {
      ...currentValues,
    },
    validationSchema: validationSchema,
    onSubmit: onSubmitForm,
    validateOnBlur: true,
  });

  const handleOnBack = useCallback(() => {
    store.dispatch({ type: REGISTER_CLEAR });
    back();
  }, [back]);

  return (
    <Main>
      <ActionBarHeader
        nativeIDPressable="RegisterPersonalInfo.action-go-back"
        nativeIDTitle="RegisterPersonalInfo.actionTitle"
        title={i18n.t<string>('CreateAccountPersonalInfo.title')}
        onBack={handleOnBack}
      />
      {isDatePickerVisible && (
        <Box
          width="100vw"
          height="100vh"
          backgroundColor="neutral.overlay"
          position="absolute"
          zIndex={1}
        />
      )}
      <ScrollContainer>
        <ContentHeader
          nativeIDTitle="RegisterPersonalInfo.title"
          nativeIDPressable="RegisterPersonalInfo.go-back"
          nativeIDSubtitle="RegisterPersonalInfo.subtitle"
          title={i18n.t<string>('CreateAccountPersonalInfo.title')}
          subtitle={i18n.t<string>(
            'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.primaryText'
          )}
          onBack={handleOnBack}
        />
        <Form onSubmit={handleSubmit}>
          <Input
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.firstName.label'
            )}
            nativeID="firstName"
            keyboardType="default"
            errorMessage={errors.firstName}
            isInvalid={errors.firstName && touched.firstName ? true : false}
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDatePickerVisible}
          />
          <Input
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.lastName.label'
            )}
            nativeID="lastName"
            keyboardType="default"
            errorMessage={errors.lastName}
            isInvalid={errors.lastName && touched.lastName ? true : false}
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            isDisabled={isDatePickerVisible}
          />
          <DateInput
            placeholder={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.placeholder'
            )}
            label={i18n.t<string>(
              'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
            )}
            isInvalid={errors.dateOfBirth && touched.dateOfBirth ? true : false}
            errorMessage={errors.dateOfBirth}
            keyboardType="default"
            nativeID="dateOfBirth"
            editable={false}
            onChange={handleChange}
            onBlur={handleBlur}
            setFieldTouched={setFieldTouched}
            value={values.dateOfBirth ? format(parseISO(values.dateOfBirth), 'MM/dd/yyyy') : ''}
            isDisabled={isDatePickerVisible}
            onClick={onClickDateInput}
          />
        </Form>
      </ScrollContainer>
      <Footer
        buttonText={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.links.next.label'
        )}
        onButtonSubmit={handleSubmit}
        nativeIDButton="RegisterPersonalInfo.nextButton"
        linkText={i18n.t<string>('CreateAccountPersonalInfo.content.footerSignIn.primaryText')}
        onButtonLinkClick={handleClickSignIn}
        nativeIDButtonLink="RegisterPersonalInfo.signIn"
        buttonLinkText={i18n.t<string>(
          'CreateAccountPersonalInfo.content.footerSignIn.links.signIn.label'
        )}
        isButtonDisabled={
          !values.firstName || !values.lastName || !values.dateOfBirth || !isValid || isSubmitting
        }
      />
      <DatePicker
        title={i18n.t<string>(
          'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.label'
        )}
        isVisible={isDatePickerVisible}
        initialDate={currentValues.dateOfBirth}
        onCancel={() => {
          setIsDatePickerVisible(false);

          if (!values.dateOfBirth) {
            setFieldError(
              'dateOfBirth',
              i18n.t<string>(
                'CreateAccountPersonalInfo.content.createAccountPersonalInfoForm.formField.dob.errors.required'
              )
            );
          }
        }}
        onSave={(date: string, resetToDate: (date: string) => void) => {
          setIsDatePickerVisible(false);

          const isValidDate = validateDOBAndDisplayError(date);

          if (isValidDate) {
            setFieldValue('dateOfBirth', date);
            return;
          }

          resetToDate(values.dateOfBirth);
        }}
      />
    </Main>
  );
};

export default connector(memo(RegisterPersonalInfo));
