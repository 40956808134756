import { MINOR_FLOW, REGISTER, REGISTER_CLEAR, RegisterActions, RegisterState } from './types';

const initialState: RegisterState = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  password: '',
  confirmPassword: '',
  guardianFirstName: '',
  guardianLastName: '',
  isMinor: false,
};

export const registerReducer = (state = initialState, action: RegisterActions): RegisterState => {
  switch (action.type) {
    case REGISTER:
      return {
        ...state,
        ...action.register,
      };
    case REGISTER_CLEAR:
      return {
        ...initialState,
      };
    case MINOR_FLOW: {
      const register = action.register ? action.register : state;

      return {
        ...state,
        ...register,
        isMinor: action.isMinorFlow,
      };
    }

    default:
      return state;
  }
};
