import { getTheme } from 'Utilities/dataHelpers';

import { NAVIGATION, NavigationAction } from '../nav/types';
import { EnvActions, EnvState, SET_PHONE_LANGUAGE } from './types';

const initialState: EnvState = {
  osType: 'none',
  osVersion: 'none',
  appVersion: 'none',
  deviceId: '',
  theme: getTheme(),
  app: 'libre1',
  deviceManufacturer: 'none',
  deviceModel: 'none',
  country: 'US',
  phoneLanguage: '',
};

export const envReducer = (
  state = initialState,
  action: NavigationAction | EnvActions
): EnvState => {
  switch (action.type) {
    case NAVIGATION:
      return {
        ...state,
        osType: action.context.query?.osType || state.osType,
        osVersion: action.context.query?.osVersion || state.osVersion,
        deviceId: action.context.query?.deviceId || state.deviceId,
        appVersion: action.context.query?.appVersion || state.appVersion,
        theme: action.context.query?.theme || state.theme,
        app: action.context.query?.app || state.app,
        deviceManufacturer: action.context.query?.deviceManufacturer || state.deviceManufacturer,
        deviceModel: action.context.query?.deviceModel || state.deviceModel,
        country: action.context.query?.country || state.country,
      };
    case SET_PHONE_LANGUAGE:
      return {
        ...state,
        phoneLanguage: action.phoneLanguage,
      };
    default:
      return state;
  }
};
