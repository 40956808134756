import { generateUserAgentHeader, urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const handleConsent = (
  patientId: string,
  action: string,
  osType: string,
  osVersion: string,
  app: string,
  appVersion: string,
  dataId: 'realWorldEvidence' | 'hipaa'
) => {
  return new Promise<ConsentActionResponse>((resolve, reject) => {
    mediator
      .request<ApiResponse<ConsentActionResponse>>('api:invoke', {
        method: 'PUT',
        url: urls.apiUrl + 'patients/' + patientId + '/consents',
        headers: {
          'X-User-Agent': generateUserAgentHeader(app, appVersion, osType, osVersion),
        },
        data: {
          id: dataId,
          action: action,
        },
      })
      .then((result) => {
        if (result.data) {
          return resolve(result.data);
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
